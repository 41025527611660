import * as React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Navbar lang="ja" />
        <div>
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow: "0.5rem 0 0 #044E87, -0.5rem 0 0 #044E87",
              backgroundColor: "#044E87",
              color: "white",
              padding: "1rem",
            }}
          >
            最近の活動
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll maxitem={50} lang="ja" dtype="news" />
            </div>
          </div>
        </section>
        <Footer lang="ja" />
      </Layout>
    );
  }
}
